import {ApiBridge} from '../shared/internal/api-bridge'
import {EmbeddedMetaModule} from './module/embedded-meta-module'
import {ConversationModule} from '../shared/internal/module/conversation-module'
import {AgentAvailabilityModule} from '../shared/internal/module/agent-availability-module'
import {GeneralModule} from "./module/general-module"
import {Configuration} from "../../shared/model/configuration";

export class InternalEmbeddedApi {
    public readonly meta: EmbeddedMetaModule
    public readonly general: GeneralModule
    public readonly conversation: ConversationModule
    public readonly agentAvailability: AgentAvailabilityModule

    constructor(private readonly bridge: ApiBridge, public readonly configuration: Configuration) {
        this.meta = new EmbeddedMetaModule(bridge)
        this.general = new GeneralModule(bridge)
        this.conversation = new ConversationModule(bridge)
        this.agentAvailability = new AgentAvailabilityModule(bridge)
    }

    public checkCompatibility(): void {
        this.bridge.checkCompatibility()
    }
}