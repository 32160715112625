import {UnbluUtil} from "./shared/internal/unblu-util"

//first create the unblu global object as it is needed by the embedded app element
UnbluUtil.getUnbluObject() || UnbluUtil.createUnbluObject()

export {
    UnbluEmbeddedAppElement,
    StateChangeEvent,
    ReadyEvent,
    ErrorEvent,
    DeinitializationStartedEvent,
    DeinitializationFinishedEvent,
    ActiveConversationChangeEvent,
    FullscreenChangeEvent,
    ViewModeChangeEvent
} from "./unblu-embedded-app-element"

export {
    UnbluEmbeddedApi,
    ActiveConversationChangeListener,
    AgentAvailabilityChangeListener,
    AgentAvailableChangeListener,
    ConversationsChangeListener,
    DeinitializationListener,
    NotificationCountChangeListener,
    PersonChangeListener,
    PersonActivityListener,
    FullscreenChangeListener,
    ViewModeChangeListener
} from "./unblu-embedded-api"
export {UnbluApiError, UnbluErrorType} from "./shared/unblu-api-error"
export {ApiState} from "./shared/api-state"
export {CallState} from "./shared/model/call-state"
export {ConnectionState} from './shared/model/connection-state'
export {ConversationType} from './shared/model/conversation-type'
export {PersonInfo} from "./shared/model/person-info"
export {Conversation} from "./shared/conversation"
export {ConversationInfo} from "./shared/model/conversation-info"
export {AgentAvailabilityState} from "./shared/model/agent-availability-state"
export {NewConversationInterceptor} from "./shared/new-conversation-interceptor"
export {NewConversationInterceptorResult} from "./shared/model/new-conversation-interceptor-result"
export {ConversationRecipient} from "./shared/model/conversation-recipient"
export {ConversationRecipientType} from "./shared/model/conversation-recipient-type"
export {ViewMode} from "./view-mode"
export {InvitationStatus} from "./shared/model/invitation-status"
export {Invitation} from "./shared/model/invitation"