import {InitializedUnbluApi} from "./shared/internal/initialized-unblu-api"
import {Conversation} from "./shared/conversation"
import {ConversationInfo} from "./shared/model/conversation-info"
import {PersonInfo} from "./shared/model/person-info"
import {AgentAvailabilityState} from "./shared/model/agent-availability-state"
import {AgentAvailabilityEventType} from "./shared/internal/module/agent-availability-module"
import {InternalModule} from "./shared/internal/module/module"
import {UnbluApiError, UnbluErrorType} from "./shared/unblu-api-error"
import {InternalEmbeddedApi} from "./internal/internal-embedded-api"
import {EmbeddedMetaEventType} from "./internal/module/embedded-meta-module"
import {ViewMode} from "./view-mode"
import {NewConversationInterceptor} from "./shared/new-conversation-interceptor"
import {ConversationType} from "./shared/model/conversation-type"
import {Event, EventCallback} from "./shared/internal/event"
import {GeneralEventType} from "./internal/module/general-module"
import {UnbluUtil} from "./shared/internal/unblu-util";
import {ConversationRecipient} from "../shared/model/conversation-recipient";

/**
 * Listener called whenever the active conversation changes.
 *
 * **Note:** If no conversation is currently active the passed conversation object will be `null`
 * @param conversation API object for the active conversation or `null` if no conversation is active.
 *
 * @see [[UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 * @see [[UnbluEmbeddedAppElement.ActiveConversationChangeEvent]] for the corresponding custom event on the [[UnbluEmbeddedAppElement]]
 */
export type ActiveConversationChangeListener = (conversation?: Conversation) => void

/**
 * Listener called whenever a conversation changed, added or removed from all conversations.
 * @param conversations All conversations of the current visitor.
 *
 * @see [[UnbluEmbeddedApi.CONVERSATIONS_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type ConversationsChangeListener = (conversations: ConversationInfo[]) => void

/**
 * Listener called whenever the notification count of a person (i.e. unread messages) changes.
 * @param count The number of unseen notifications.
 *
 * @see [[UnbluEmbeddedApi.NOTIFICATION_COUNT_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type NotificationCountChangeListener = (count: number) => void

/**
 * Listener called whenever the local person changes.
 * @param person Info about the person.
 *
 * @see [[UnbluEmbeddedApi.PERSON_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type PersonChangeListener = (person: PersonInfo) => void

/**
 * Listener called whenever there is activity by the person.
 * @param lastActivity A UTC timestamp when the last activity happened.
 *
 * @see [[UnbluEmbeddedApi.PERSON_ACTIVITY]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type PersonActivityListener = (person: PersonInfo) => void

/**
 * Listener called whenever the agent availability changes.
 * @param isAvailable A boolean that indicates if an agent is available.
 *
 * @see [[UnbluEmbeddedApi.AGENT_AVAILABLE_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type AgentAvailableChangeListener = (isAvailable: boolean) => void

/**
 * Listener called whenever the agent availability state changes.
 * @param availability The new availability state.
 *
 * @see [[UnbluEmbeddedApi.AGENT_AVAILABILITY_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 */
export type AgentAvailabilityChangeListener = (availability: AgentAvailabilityState) => void

/**
 * Listener called whenever the embedded app switches to fullscreen mode or back
 * @param fullscreen The new flag for the fullscreen mode.
 *
 * @see [[UnbluEmbeddedApi.FULLSCREEN_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 * @see [[UnbluEmbeddedAppElement.FullscreenChangeEvent]] for the corresponding custom event on the [[UnbluEmbeddedAppElement]]
 */
export type FullscreenChangeListener = (fullscreen: boolean) => void

/**
 * Listener called whenever the view mode changes.
 * @param viewMode The new view mode.
 *
 * @see [[UnbluEmbeddedApi.VIEW_MODE_CHANGE]]
 * @see [[UnbluEmbeddedApi.on]] to register for the event
 * @see [[UnbluEmbeddedApi.off]] to unregister from the event
 * @see [[UnbluEmbeddedAppElement.ViewModeChangeEvent]] for the corresponding custom event on the [[UnbluEmbeddedAppElement]]
 */
export type ViewModeChangeListener = (viewMode: ViewMode) => void

/**
 * Listener called whenever the UnbluApi gets de-initialized.
 *
 * @see [[UnbluEmbeddedApi.DEINITIALIZING]] and [[UnbluEmbeddedApi.DEINITIALIZED]]
 * @see [[UnbluEmbeddedApi.on]] to register for the events
 * @see [[UnbluEmbeddedApi.off]] to unregister from the events
 * @see [[UnbluEmbeddedAppElement.DeinitializationStartedEvent]] and [[UnbluEmbeddedAppElement.DeinitializationFinishedEvent]] for the corresponding custom events on the [[UnbluEmbeddedAppElement]]
 */
export type DeinitializationListener = () => void

/**
 * This is the main class to interact with the Unblu embedded web component after initialization is done.
 *
 * An instance of the [[UnbluEmbeddedApi]] is returned by calling [[UnbluEmbeddedAppElement.initialize]] or listening to the [[UnbluEmbeddedAppElement.READY]] event.
 *
 * **Note**: After deinitialization an instance of [[UnbluEmbeddedApi]] is no longer usable and will throw errors on API calls.
 * In order to access the API again, it is required to initialize Unblu again.
 */
export class UnbluEmbeddedApi implements InitializedUnbluApi {

    /**
     * Event emitted every time the active conversation changes.
     *
     * This may happen due to a UI-navigation or an API-call.
     *
     * @event activeConversationChange
     * @see [[on]] for listener registration
     * @see [[ConversationChangeListener]]
     */
    public static readonly ACTIVE_CONVERSATION_CHANGE: 'activeConversationChange' = 'activeConversationChange'

    /**
     * Event emitted every time one of the conversations accessible to the current user changes or one is added or removed.
     *
     * @event conversationsChanged
     * @see [[on]] for listener registration
     * @see [[ConversationsChangeListener]]
     */
    public static readonly CONVERSATIONS_CHANGE: 'conversationsChange' = 'conversationsChange'

    /**
     * Event emitted every time the notification count (unread messages) changes.
     *
     * @event notificationCountChange
     * @see [[on]] for listener registration
     * @see [[NotificationCountChangeListener]]
     */
    public static readonly NOTIFICATION_COUNT_CHANGE: 'notificationCountChange' = 'notificationCountChange'

    /**
     * Event emitted every time the local person changes. This may be i.e. due to the person setting its name.
     *
     * @event personChange
     * @see [[on]] for listener registration
     * @see [[PersonChangeListener]]
     */
    public static readonly PERSON_CHANGE: 'personChange' = 'personChange'

    /**
     * Event emitted every time the local person has some activity inside Unblu.
     * This may be i.e. an interaction with the chat, a call, opening a conversation or interacting
     * with a co-browsing layer.
     *
     * The event can be used to reset the logout timer inside an authenticated area, for example.
     *
     * The configuration property com.unblu.conversation.activity.activityCategoriesToTrack specifies which categories of activity trigger the event.
     *
     * @event personActivity
     * @see [[on]] for listener registration
     * @see [[PersonActivityListener]]
     */
    public static readonly PERSON_ACTIVITY: 'personActivity' = 'personActivity'

    /**
     * Event emitted every time the agent availability changes for the current named area and locale.
     *
     * @event availableChange
     * @see [[on]] for listener registration
     * @see [[AgentAvailableChangeListener]]
     */
    public static readonly AGENT_AVAILABLE_CHANGE: 'availableChange' = 'availableChange'

    /**
     * Event emitted every time the agent availability state changes for the current named area and locale.
     *
     * @event availabilityChange
     * @see [[on]] for listener registration
     * @see [[AgentAvailabilityChangeListener]]
     */
    public static readonly AGENT_AVAILABILITY_CHANGE: 'availabilityChange' = 'availabilityChange'

    /**
     * Event emitted every time the embedded app switches to fullscreen mode or back.
     *
     * @event fullscreenChange
     * @see [[on]] for listener registration
     * @see [[FullscreenChangeListener]]
     */
    public static readonly FULLSCREEN_CHANGE: 'fullscreenChange' = 'fullscreenChange'

    /**
     * Event emitted every time the view mode changes.
     *
     * @event viewModeChange
     * @see [[on]] for listener registration
     * @see [[FullscreenChangeListener]]
     */
    public static readonly VIEW_MODE_CHANGE: 'viewModeChange' = 'viewModeChange'

    /**
     * Event emitted when this instance gets de-initialized and is not usable at the time until it fully got de-initialized.
     *
     * @event deinitializing
     * @see [[on]] for listener registration
     * @see [[DeinitializationListener]]
     */
    public static readonly DEINITIALIZING: 'deinitializing' = 'deinitializing'

    /**
     * Event emitted when this instance got de-initialized and has to be initialized again.
     *
     * **Note**: After this event, the instance of [[UnbluEmbeddedApi]] is no longer usable.
     *
     * @event deinitialized
     * @see [[on]] for listener registration
     * @see [[DeinitializationListener]]
     */
    public static readonly DEINITIALIZED: 'deinitialized' = 'deinitialized'

    private _internalApi: InternalEmbeddedApi

    /**
     * @hidden
     */
    constructor(internalApi: InternalEmbeddedApi) {
        this._internalApi = internalApi
    }

    // Event
    /**
     * Registers an event listener for the given event.
     * @param event The activeConversationChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE, listener: ActiveConversationChangeListener): void

    /**
     * Registers an event listener for the given event.
     * @param event The conversationsChanged event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.CONVERSATIONS_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.CONVERSATIONS_CHANGE, listener: ConversationsChangeListener): void

    /**
     * Registers an event listener for the given event.
     * @param event The notificationCountChanged event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.NOTIFICATION_COUNT_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.NOTIFICATION_COUNT_CHANGE, listener: NotificationCountChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The personChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.PERSON_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.PERSON_CHANGE, listener: PersonChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The personActivity event.
     * @param listener The listener to be called.
     * @see [[PERSON_ACTIVITY]]
     */
    public on(event: typeof UnbluEmbeddedApi.PERSON_ACTIVITY, listener: PersonActivityListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The agentAvailableChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.AGENT_AVAILABLE_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.AGENT_AVAILABLE_CHANGE, listener: AgentAvailableChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The agentAvailabilityChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.AGENT_AVAILABILITY_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.AGENT_AVAILABILITY_CHANGE, listener: AgentAvailabilityChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The fullscreenChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.FULLSCREEN_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.FULLSCREEN_CHANGE, listener: FullscreenChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The viewModeChange event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.VIEW_MODE_CHANGE]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.VIEW_MODE_CHANGE, listener: ViewModeChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The deinitializing event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.DEINITIALIZED]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.DEINITIALIZING, listener: DeinitializationListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The deinitialized event.
     * @param listener The listener to be called.
     * @see [[UnbluEmbeddedApi.DEINITIALIZED]]
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public on(event: typeof UnbluEmbeddedApi.DEINITIALIZED, listener: DeinitializationListener): void

    public async on(event: GeneralEventType | AgentAvailabilityEventType | EmbeddedMetaEventType, listener: (data?: any) => void) {
        this.assertNotDeinitialized()
        let internalListener: EventCallback
        let internalModule: InternalModule<any, any>
        switch (event) {
            case UnbluEmbeddedApi.AGENT_AVAILABLE_CHANGE:
            case UnbluEmbeddedApi.AGENT_AVAILABILITY_CHANGE:
                internalListener = (event: Event<boolean | AgentAvailabilityState>) => listener(event.data)
                internalModule = this._internalApi.agentAvailability
                break
            case UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE:
                internalListener = (event: Event<string>) => listener(event.data ? this.buildConversation(event.data) : null)
                internalModule = this._internalApi.general
                break
            case UnbluEmbeddedApi.CONVERSATIONS_CHANGE:
            case UnbluEmbeddedApi.NOTIFICATION_COUNT_CHANGE:
            case UnbluEmbeddedApi.PERSON_CHANGE:
            case UnbluEmbeddedApi.PERSON_ACTIVITY:
            case UnbluEmbeddedApi.FULLSCREEN_CHANGE:
            case UnbluEmbeddedApi.VIEW_MODE_CHANGE:
                internalListener = (event: Event<ConversationInfo[] | number | PersonInfo>) => listener(event.data)
                internalModule = this._internalApi.general
                break
            case UnbluEmbeddedApi.DEINITIALIZING:
            case UnbluEmbeddedApi.DEINITIALIZED:
                internalListener = (event: Event<void>) => listener(event.data)
                internalModule = this._internalApi.meta
                break
            default:
                throw new UnbluApiError(UnbluErrorType.INVALID_FUNCTION_ARGUMENTS, 'Registration to unknown event:' + event)
        }
        await internalModule.on(event, internalListener)
    }

    /**
     * Removes a previously registered listener
     * @param event The event to unregister from.
     * @param listener The listener to remove.
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async off(event: GeneralEventType | AgentAvailabilityEventType | EmbeddedMetaEventType, listener: (data?: any) => void) {
        this.assertNotDeinitialized()
        let internalModule: InternalModule<any, any>
        switch (event) {
            case UnbluEmbeddedApi.AGENT_AVAILABILITY_CHANGE:
                internalModule = this._internalApi.agentAvailability
                break
            case UnbluEmbeddedApi.DEINITIALIZING:
            case UnbluEmbeddedApi.DEINITIALIZED:
                internalModule = this._internalApi.meta
                break
            default:
                internalModule = this._internalApi.general
                break
        }
        await internalModule.off(event, listener)
    }

    /**
     * Opens the conversation for the given conversation ID.
     *
     * If the conversation doesn't exist or the user doesn't have the right to access it, a dialog will be displayed with the respective error message.
     * Be aware that in such a case the promise will still resolve successfully as the UI switched into the conversation view showing the error dialog. - The active conversation property will however be set to `null`.
     *
     * @see [[UnbluEmbeddedAppElement.ATTR_CONVERSATION_ID]] to set this as attribute
     * @param conversationId The id of the conversation to open. Using null will close the current conversation and open the overview of conversations
     * @return A promise that resolves with the opened conversation instance, `null` if the overview was opened, or it is rejected with an error if it did not work.
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async openConversation(conversationId: string): Promise<Conversation> {
        this.assertNotDeinitialized()
        conversationId = UnbluUtil.sanitizeParameter(conversationId)
        let activeConversation;
        if (conversationId) {
            await this._internalApi.general.openConversation(conversationId)
        } else if ((activeConversation = await this.getActiveConversation().catch(() => null)) != null) {
            //close the current active conversation
            await activeConversation.closeConversation()
        }
        return this.getActiveConversation()
    }

    /**
     * Returns the currently active conversation or `null` if no conversation is active.
     *
     * **NOTE:** calling this method twice while the same conversation is active, will result in two individual conversation API instances being returned.
     * destroying one of them will not cause the other one to also be destroyed. If however the active conversation is closed, all returned Conversation instances will be destroyed.
     *
     * @return A promise that either resolves to the currently active conversation or `null` if no conversation is open.
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     * @see [[ACTIVE_CONVERSATION_CHANGE]] for the event whenever the active conversation changes
     */
    public async getActiveConversation(): Promise<Conversation | null> {
        this.assertNotDeinitialized()
        return this._internalApi.general.getActiveConversation().then(conversationId => this.buildConversation(conversationId))
    }

    /**
     * All conversations accessible to the current user.
     * If no conversation is present, an empty array is returned.
     *
     * @return A promise that resolves to a list of all conversations accessible to the current user.
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async getConversations(): Promise<ConversationInfo[]> {
        this.assertNotDeinitialized()
        return this._internalApi.general.getConversations()
    }

    /**
     * Sets the view mode of the embedded app element.
     *
     * Depending on the view mode the UI is displayed the following way:
     * - [[ViewMode.SPLIT]]: The UI will be divided into two parts.
     * The list of conversations is displayed on the left and the active conversation on the right side.
     *
     * - [[ViewMode.SINGLE]]: Both the conversation list and the active conversation will be displayed in the same view using UI navigation to switch between them.
     *
     * - [[ViewMode.AUTO]]: The UI will automatically switch back and forth between the two other modes depending on the available width.
     * The trigger point when to switch between the two modes can be configured on the Unblu collaboration server.
     *
     * @param viewMode The new view mode to use
     * @return A promise that resolves as soon as the value has been applied
     * @default [[ViewMode.AUTO]]
     *
     * @see [[getViewMode]] to retrieve the current value
     * @see [[UnbluEmbeddedAppElement.viewMode]] for the corresponding property on the element
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async setViewMode(viewMode: ViewMode): Promise<void> {
        this.assertNotDeinitialized()
        if (viewMode == null) {
            viewMode = ViewMode.AUTO
        }
        await this._internalApi.general.setViewMode(viewMode)
    }

    /**
     * Returns the view mode of the embedded app element.
     *
     * @default [[ViewMode.AUTO]]
     * @return A promise that resolves with the current view mode
     *
     * @see [[setViewMode]] to set the value and for further details on the view mode
     * @see [[UnbluEmbeddedAppElement.viewMode]] for the corresponding property on the element
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async getViewMode(): Promise<ViewMode> {
        this.assertNotDeinitialized()
        return this._internalApi.general.getViewMode()
    }

    /**
     * When this attribute is set to true, the embedded app element will ignore the position and size set to its element and will used fixed positioning to display itself to cover the whole website.
     *
     * **Note**: The user itself can also toggle this inside the UI.
     *
     * @param fullscreen True to enable fullscreen, false otherwise.
     * @return A promise that resolves as soon as the value has been applied
     * @default false
     *
     * @see [[isFullscreen]] to retrieve the current value
     * @see [[UnbluEmbeddedAppElement.fullscreen]] for the corresponding property on the element
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async setFullscreen(fullscreen: boolean): Promise<void> {
        this.assertNotDeinitialized()
        await this._internalApi.general.setFullscreen(fullscreen)
    }

    /**
     * Returns true if the UI is currently displayed in fullscreen, false otherwise.
     *
     * @default false
     * @return A promise that resolves with true if the UI is in fullscreen mode, false otherwise
     *
     * @see [[setFullscreen]] to set the value and for further details on the view mode
     * @see [[UnbluEmbeddedAppElement.fullscreen]] for the corresponding property on the element
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async isFullscreen(): Promise<boolean> {
        this.assertNotDeinitialized()
        return this._internalApi.general.isFullscreen()
    }

    /**
     * Returns the information about the current person.
     *
     * @return A promise that resolves with the current person information
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async getPersonInfo(): Promise<PersonInfo> {
        this.assertNotDeinitialized()
        return this._internalApi.general.getPersonInfo()
    }

    /**
     * Sets the nickname of the current user.
     *
     * This is only respected for anonymous visitors.
     *
     * @return A promise that resolves as soon as the value has been applied
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async setPersonNickname(nickname: string): Promise<void> {
        this.assertNotDeinitialized()
        // noinspection JSIgnoredPromiseFromCall
        return this._internalApi.general.setPersonNickname(nickname)
    }

    /**
     * Logs the current visitor in with the access token provided.
     * Depending on the configuration, existing conversation may will be transferred to the authenticated user.
     * @param accessToken The access token (JWT) to authenticate the visitor with
     * @return A promise that resolves empty if the login succeeds or is rejected if it fails
     */
    public async login(accessToken: string): Promise<void> {
        this.assertNotDeinitialized()
        return UnbluUtil.loginWithSecureToken(this._internalApi.configuration.serverUrl || '', this._internalApi.configuration.apiKey, this._internalApi.configuration.entryPath || '/unblu', accessToken)
    }

    /**
     * Checks if the current visitor is authenticated.
     * @return A promise that resolves to a boolean if the visitor is authenticated
     */
    public async isAuthenticated(): Promise<boolean> {
        this.assertNotDeinitialized()
        return UnbluUtil.isAuthenticated(this._internalApi.configuration.serverUrl || '', this._internalApi.configuration.entryPath || '/unblu')
    }

    /**
     * Logs the visitor out.
     * The user will not have access to conversations from the authenticated visitor he was before anymore. He needs to be authenticated again for that.
     * @return A promise that resolves empty when the logout succeeds or is rejected if it fails
     */
    public async logout(): Promise<void> {
        this.assertNotDeinitialized()
        return UnbluUtil.logout(this._internalApi.configuration.serverUrl || '', this._internalApi.configuration.entryPath || '/unblu')
    }

    /**
     * Returns the number of unread messages.
     *
     * @return A promise that resolves with the number of unread messages
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async getNotificationCount(): Promise<number> {
        this.assertNotDeinitialized()
        return this._internalApi.general.getNotificationCount()
    }

    /**
     * Returns true if an agent is available for the current named area and language. False otherwise.
     *
     * @return A promise that resolves with true if an agent is available, false otherwise
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async isAgentAvailable(): Promise<boolean> {
        this.assertNotDeinitialized()
        return this._internalApi.agentAvailability.isAgentAvailable()
    }

    /**
     * Returns the current availability state for the current named area and language.
     *
     * @return A promise that resolves with the current agent availability state
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async getAgentAvailabilityState(): Promise<AgentAvailabilityState> {
        this.assertNotDeinitialized()
        return this._internalApi.agentAvailability.getAgentAvailabilityState()
    }

    /**
     * Sets a custom interceptor which will be triggered when a new conversation is started (initiated from UI or JavaScript).
     *
     * @param newConversationInterceptor The interceptor which should be triggered on new conversations
     *
     * @return A promise that resolves as soon as the new interceptor has been applied
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async setNewConversationInterceptor(newConversationInterceptor: NewConversationInterceptor): Promise<void> {
        this.assertNotDeinitialized()
        return this._internalApi.general.setNewConversationInterceptor(newConversationInterceptor)
    }

    /**
     * Joins an existing conversation with a given PIN.
     *
     * @param pin The PIN retrieved from the Unblu Agent Desk.
     * @param visitorName The name the local visitor should have. This is only taken into account if the visitor is not already authenticated.
     * @return A promise that resolves to the conversation which was joined
     *
     * @throws [[UnbluApiError]] with type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized.
     */
    public async joinConversation(pin: string, visitorName?: string): Promise<Conversation> {
        this.assertNotDeinitialized()
        return this._internalApi.general.joinConversation(pin, visitorName).then(conversationId => this.buildConversation(conversationId))
    }

    /**
     * Starts a new conversation and places it into the inbound conversation queue.
     *
     * Starting a new conversation involves an agent availability check.
     * For [[ConversationType.OFFLINE_CHAT_REQUEST]] conversations, the check proceeds as follows:
     * * If an agent is available, the conversation type will be changed to [[ConversationType.CHAT_REQUEST]].
     * * If no agents are available, it will start an offline conversation provided offline chat requests are enabled in the Unblu server's configuration.
     * * if offline chat requests aren't enabled, the request will be rejected.
     *
     * For all `online` conversation types, the check works as follows:
     * * If an agent is available, the conversation will be started.
     * * If no agents are available, the request will be rejected.
     *
     * You should therefore always check agent availability before starting a new conversation.
     * If no agents are available, only start conversations of the type [[ConversationType.OFFLINE_CHAT_REQUEST]].
     *
     * @param type The type of the conversation
     * @param visitorName The name of the visitor. This is only respected for anonymous visitors
     * @param visitorData Custom visitor data that will be stored on the conversation and can be accessed through the Web API later on. This data won't be displayed anywhere and is for API usage only.
     * @param recipient The team or agent recipient of the conversation. This will overwrite any named area that might be set for this web page.
     * @return A promise that resolves to the conversation which was started
     *
     * @throws [[UnbluApiError]] of type [[UnbluErrorType.ILLEGAL_STATE]] when the API instance is deinitialized or if the conversation type may not be started.
     */
    public async startConversation(type: ConversationType, visitorName?: string, visitorData?: string, recipient?: ConversationRecipient): Promise<Conversation> {
        this.assertNotDeinitialized()
        return this._internalApi.general.startConversation(type, visitorName, visitorData, recipient).then(conversationId => this.buildConversation(conversationId))
    }

    private buildConversation(conversationId: string | null): Conversation {
        if (conversationId != null) {
            return new Conversation(this._internalApi.conversation, conversationId)
        } else {
            return null
        }
    }

    private assertNotDeinitialized() {
        if (this.isDeinitialized()) {
            throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Error: trying to execute method on deinitialized UnbluApi instance.')
        }
    }

    /**
     * Returns true if this instance of the API is deinitialized, false otherwise.
     */
    public isDeinitialized(): Boolean {
        return this._internalApi == null
    }

    /**
     * Deinitializes the Unblu Embedded Web Component.
     * This means that both API is no longer usable but also the UI is completely destroyed.
     */
    public async deinitialize(): Promise<void> {
        if (this.isDeinitialized()) {
            return
        }
        await this._internalApi.meta.deinitialize()
        this._internalApi = null
    }
}